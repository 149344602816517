import React, { useMemo } from 'react';
import UserDetails from '~/components/UserDetails';
import ProfilePic from '~/components/ProfilePic';
import { useNavigate } from 'react-router-dom';
import { useChatContext } from '~/contexts';
import { borderRadius, Color, mobileMaxWidth } from '~/constants/css';
import { css } from '@emotion/css';

export default function DefaultComponent({
  src,
  pageType,
  subPageType,
  profile
}: {
  src: string;
  pageType?: string;
  subPageType?: string;
  profile: {
    id: number;
    username: string;
    profilePicUrl: string;
  };
  profileId: number;
}) {
  const navigate = useNavigate();
  const chatStatus = useChatContext((v) => v.state.chatStatus);
  const heading = useMemo(() => {
    switch (pageType) {
      case 'watched':
        return `XP Videos Watched by ${profile.username}`;
      case 'likes':
        return `All Contents Liked by ${profile.username}`;
      case 'all':
        if (subPageType === 'byuser') {
          return `All Contents Made by ${profile.username}`;
        }
        return `All Contents Posted by ${profile.username}`;
      case 'comments':
        return `All Comments Posted by ${profile.username}`;
      case 'subjects':
        if (subPageType === 'byuser') {
          return `Subjects Made by ${profile.username}`;
        }
        return `Subjects Posted by ${profile.username}`;
      case 'ai-stories':
        return `AI Stories Generated by ${profile.username}`;
      case 'videos':
        if (subPageType === 'byuser') {
          return `Videos Made by ${profile.username}`;
        }
        return `Videos posted by ${profile.username}`;
      case 'links':
        if (subPageType === 'byuser') {
          return `Links made by ${profile.username}`;
        }
        return `Links posted by ${profile.username}`;
      default:
        return '';
    }
  }, [pageType, profile?.username, subPageType]);

  return (
    <div
      onClick={() => navigate(src)}
      className={css`
        .label {
          font-size: 2.2rem;
          color: ${Color.black()};
        }
        width: 100%;
        background: #fff;
        padding: 1rem;
        transition: background 0.5s;
        border: 1px solid ${Color.borderGray()};
        border-radius: ${borderRadius};
        cursor: pointer;
        &:hover {
          background: ${Color.highlightGray()};
        }
        @media (max-width: ${mobileMaxWidth}) {
          .label {
            font-size: 1.8rem;
          }
        }
      `}
    >
      {heading ? (
        <div
          className={`label ${css`
            font-weight: bold;
            margin-bottom: 1.5rem;
            overflow-wrap: break-word;
            word-break: break-word;
          `}`}
        >
          {heading}
        </div>
      ) : null}
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <div
          className={css`
            width: 25%;
          `}
        >
          <ProfilePic
            userId={profile.id}
            profilePicUrl={profile.profilePicUrl || ''}
            online={chatStatus[profile.id]?.isOnline}
            statusShown
            large
          />
        </div>
        <div
          className={css`
            margin-left: 3rem;
            width: CALC(75% - 3rem);
          `}
        >
          <UserDetails
            noLink
            small
            unEditable
            profile={profile}
            userId={profile.id}
          />
        </div>
      </div>
    </div>
  );
}
